<template>
  <div>
    <div class="d-flex flex-no-wrap">
      <v-hover v-if="canDownloadPortrait">
        <template v-slot:default="{ hover }">
          <v-avatar class="ma-3" size="90">
            <PortraitImage :value="person" large />
            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                color="#000000"
                class="d-flex align-end"
              >
                <span class="text-caption">klicken zum herunterladen</span>
                <v-btn @click="fetchPortraitImage" icon
                  ><v-icon>mdi-download</v-icon>
                </v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-avatar>
        </template>
      </v-hover>
      <v-avatar v-else class="ma-3" size="90">
        <PortraitImage :value="person" large />
      </v-avatar>
      <div>
        <v-card-title class="text-h5"
          ><PersonName :value="person" title
        /></v-card-title>
        <v-card-subtitle>
          {{ personSubtitle(person) }}
        </v-card-subtitle>
      </div>
    </div>
    <v-container class="ma-0 pa-0">
      <v-row>
        <v-col cols="12" lg="4">
          <ContactEmail :person="person" class="mb-4"></ContactEmail>
          <ContactSchool
            v-if="!$_hasRole('student')"
            :person="person"
          ></ContactSchool>
        </v-col>
        <v-col cols="12" lg="4" v-if="!$_hasRole('student')">
          <ContactPersonal :person="person" />
        </v-col>
        <v-col cols="12" lg="4">
          <ContactLegalGuardians :person="person" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { personName, personSubtitle } from "common/utils/people.js";
import ContactEmail from "common/components/Person/ContactEmail.vue";
import ContactLegalGuardians from "common/components/Person/ContactLegalGuardians.vue";
import ContactPersonal from "common/components/Person/ContactPersonal.vue";
import ContactSchool from "common/components/Person/ContactSchool.vue";

import PortraitImage from "common/components/PortraitImage.vue";
import PersonName from "common/components/PersonName.vue";

export default defineComponent({
  name: "PersonContact",
  components: {
    ContactEmail,
    ContactLegalGuardians,
    ContactPersonal,
    ContactSchool,
    PortraitImage,
    PersonName,
  },
  props: ["person"],
  watch: {
    person() {},
  },
  computed: {
    canDownloadPortrait() {
      return (
        this.person &&
        this.person.portraitAvailable &&
        (!this.$_hasRole("student") ||
          this.$_profilePerson.id == this.person.id)
      );
    },
  },
  methods: {
    personSubtitle,
    async fetchData() {},
    async fetchPortraitImage() {
      const url = await this.apiBlob({
        path: "person/portrait/" + this.person.id,
      });
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = personName(this.person) + ".jpg";
        link.click();
      }
    },
  },
});
</script>
